<template>
    <div>
        <div class="descText">
            Please use the following buttons to select a geometry type to draw.
            While the button is blue, click on the map to place a point or
            vertex. Double click to finish the geometry.
        </div>
        <div class="tool-btns">
            <button
                class="tool-btn"
                v-bind:class="
                    activeMapTool == 'drawPoint'
                        ? 'btn-selected'
                        : 'btn-unselected'
                "
                @click="drawPoint()"
            >
                Draw Point
            </button>
            <button
                class="tool-btn"
                v-bind:class="
                    activeMapTool == 'drawLine'
                        ? 'btn-selected'
                        : 'btn-unselected'
                "
                @click="drawLine()"
            >
                Draw Line
            </button>
            <button
                class="tool-btn"
                v-bind:class="
                    activeMapTool == 'drawPolygon'
                        ? 'btn-selected'
                        : 'btn-unselected'
                "
                @click="drawPolygon()"
            >
                Draw Polygon
            </button>
            <!-- <button @click="showAttributeModal">show att. modal</button><br /> -->
        </div>
        <div class="d-flex justify-content-end cancel-btns">
            <button
                v-if="activeMapTool != ''"
                class="btn btn-clear btn-buffer"
                @click="cancelDraw()"
            >
                Cancel</button
            ><br />
            <button class="btn btn-clear btn-buffer" @click="clearGeometry()">
                Clear</button
            ><br />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { drawingMixin } from '../../../mixins/drawing.mixin';
export default {
    name: 'WhiteboardDraw',
    components: {},
    mixins: [drawingMixin],
    props: {},
    data() {
        return {};
    },
    methods: {
        ...mapActions('oneMap', ['clearWhiteboardGeoms']),

        getMarkerFeature(lat, lng) {
            if (
                ['drawPoint', 'drawLine', 'drawPolygon'].includes(
                    this.activeMapTool
                )
            ) {
                return {
                    // using letters and the count to create unique values between different object arrays
                    //    since point 1 and line 1 would be overlapping and throw lots of warnings in the console
                    //    but not errors that will cause issues in the actual execution/functionality
                    // for this tool we use C, L, P for Circle, Line, and Polygon
                    id: 'C' + this.drawMarkers.length,
                    geometry: {
                        type: 'Point',
                        coordinates: [lat, lng],
                        coordSys: 'LatLong',
                    },
                    properties: {},
                    styling: {
                        //SymbologyColour: this.map3dShapeColor,
                        SymbologySize: 3,
                        //PolygonColor: this.map3dShapeColor,
                        PolygonTransparency: 0,
                    },
                };
            }
        },

        clearGeometry() {
            this.cancelDraw();
            this.clearWhiteboardGeoms();
        },
    },
    computed: {
        ...mapState('oneMap', ['drawMarkers']),
    },
    watch: {},
};
</script>

<style scoped>
.tool-btns {
    padding-bottom: 10px;
}
.cancel-btns {
    flex-flow: row;
    margin-right: 20%;
}
.tool-btn {
    margin-left: 20%;
    margin-top: 10px;
    display: block;
    width: 60%;
    color: white;
    border-radius: 3px;
    border: 0;
    height: 48px;
    padding: 11px 10px 11px 10px;
}
.btn-unselected {
    background-color: #006395;
}
.btn-selected {
    background-color: #00496d;
}
</style>

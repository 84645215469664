<template>
    <div class="draw-accordion" role="tablist">
        <b-card
            v-for="(accordion, index) in accordions"
            :key="'draw-card-' + index"
            no-body
            class="mb-1"
        >
            <!-- header-tag="draw-header" -->
            <b-card-header class="p-1" role="tab">
                <b-button
                    class="adapt-blue-btn"
                    @click="updateAccordionExpand(index)"
                    block
                    >{{ accordion.label }}</b-button
                >
            </b-card-header>
            <b-collapse
                accordion="my-accordion"
                :id="'draw-collapse-' + index"
                v-model="accordion.visible"
            >
                <whiteboard-draw
                    v-if="
                        accordion.label == 'Whiteboard Draw' &&
                            accordion.visible
                    "
                ></whiteboard-draw>
                <ics-draw
                    v-if="accordion.label == 'ICS Draw' && accordion.visible"
                    :drawTypes="drawTypes"
                ></ics-draw>
                <buffer-draw
                    v-if="accordion.label == 'Buffer Draw' && accordion.visible"
                ></buffer-draw>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
Array.prototype.setAllVisiblity = function(v) {
    var i,
        n = this.length;
    for (i = 0; i < n; ++i) {
        this[i].visible = v;
    }
};

/**
 * Add any custom component as a leaflet control
 */
import WhiteboardDraw from './WhiteboardDraw.vue';
import IcsDraw from './ICSDraw.vue';
import BufferDraw from './BufferDraw.vue';

import { spatialService } from '../../../services/spatial.services.js';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'DrawPane',
    components: {
        WhiteboardDraw,
        IcsDraw,
        BufferDraw,
    },
    // don't use the drawing mixin here
    //   it breaks the watchers in the individual drawing components
    mixins: [],
    props: {},
    data() {
        return {
            accordions: [
                { label: 'Whiteboard Draw', visible: true },
                { label: 'ICS Draw', visible: false },
                { label: 'Buffer Draw', visible: false },
            ],
            drawTypes: {},
        };
    },
    created() {},
    mounted() {
        // spatialService.getDrawTypes(this.projectId).then((res) => {
        //     this.drawTypes = res.data;
        // });
        spatialService
            .getDrawTypes(this.selectedProject.ProjectID)
            .then((res) => {
                this.drawTypes = res.data;
            });
    },
    render() {
        return null;
    },
    methods: {
        ...mapActions('oneMap', ['clearMapTool']),
        cancelDraw() {
            if (
                ['drawPoint', 'drawLine', 'drawPolygon'].includes(
                    this.activeMapTool
                )
            ) {
                this.clearMapTool();
            }
        },
        updateAccordionExpand(index) {
            if (!this.accordions[index].visible) {
                this.cancelDraw();
                this.$nextTick(() => {
                    this.accordions.setAllVisiblity(false);
                    this.accordions[index].visible = true;
                });
            }
        },
    },
    computed: {
        ...mapState('oneMap', ['activeMapTool']),
        selectedProject() {
            return this.$store.state.projects.selectedProjects[0];
        },
    },
    watch: {
        selectedProject(newProject) {
            spatialService.getDrawTypes(newProject.ProjectID).then((res) => {
                this.drawTypes = res.data;
            });
        },
    },
};
</script>

<style scoped></style>
<style>
.draw-accordion {
    width: 100%;
}

.buttonContainer {
    padding: 20px;
    background-color: white;
}
.radioButton {
    display: inline-block;
}
#radioRow {
    display: flex;
    justify-content: space-around;
    padding-bottom: 5px;
}
.btn-gray {
    background-color: gray !important;
    color: white;
}
.btn-gray:focus {
    background-color: rgb(77, 194, 233) !important;
}
.btn-gray:active {
    background-color: rgb(77, 194, 233) !important;
}
.btn-gray:hover {
    background-color: darkgrey;
}
.btn-blue {
    color: white;
    background-color: #00496d !important;
}
.btn-blue:hover {
    color: white;
    background-color: #00496d !important;
}
.btn-unselected {
    color: white;
    background-color: #006395;
}
.btn-unselected:hover {
    color: white;
    background-color: #00496d !important;
}
.btn-unselected:active {
    color: white;
    background-color: #00496d !important;
}
.btn-selected {
    color: white;
    background-color: #00496d;
}
.btn-selected:hover {
    color: white;
}
.btn-selected:active {
    color: white;
}
.btn-clear {
    border: 1px solid gray;
}
.btn-buffer {
    margin-left: 10px;
}
.btn-draw {
    margin-top: 7px;
}
.label {
    margin-left: 5px;
}
.icsInput {
    width: 330px;
    border-radius: 4px;
    margin-left: 15px;
    margin-top: 8px;
    margin-bottom: 0 !important;
}
.descText {
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 10px;
    padding-bottom: 10px;
}
.drawInput {
    border: none !important;
    padding-top: 15px !important;
}
.EM {
    padding-left: 15px;
}
.topPadding {
    padding-top: 15px;
}
.ICS-SubTypes ul.dropdown-menu.show {
    overflow: auto;
    min-height: fit-content;
    max-height: 50vh;
    border-radius: 0;
    padding: 0;
    margin: 0;
    border-color: rgb(118, 118, 118);
    width: 302px;
    box-shadow: 0 2px 5px #bdbdbd !important;
}

.ICS-SubTypes ul.dropdown-menu li div {
    padding: 0px 2px 1px !important;
}
.ICS-SubTypes a.dropdown-item {
    padding: 0;
    background-color: none !important;
    cursor: default !important;
}

.ICS-SubTypes a.dropdown-item:hover {
    background-color: transparent !important;
}
.ICS-SubTypes ul.dropdown-menu li:hover {
    background-color: #007bff;
}
.ICS-SubTypes ul.dropdown-menu li a:hover {
    color: white !important;
}

input#ICS-SubTypes__input {
    padding: 0 0;
    color: black;
    font-weight: 400;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: inherit;
    font-size: 14px;
    margin-left: 5px;
}
.ICS-SubTypes button.dropdown-toggle.btn-secondary {
    background-color: transparent !important;
    border: none !important;
    height: 48px !important;
    cursor: default !important;
}
.ICS-SubTypes {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.ICS-SubTypes .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.adapt-blue-btn {
    background-color: #006395 !important;
}
</style>

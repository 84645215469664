<template>
    <div class="EM">
        <div class="descText">
            Use the buffer tool to estimate distances from a point. Enter
            desired distance(s) in Distance 1 box (Distance 2 is optional),
            click the Create button, then click on the map where the center of
            the buffer is desired. Press clear to remove the buffer
        </div>
        <div style="padding-top: 10px;" class="buffer-input">
            <custom-floating-label
                :config="{
                    label: 'Buffer Distance 1*',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <input v-model.number="bufferDistance1" type="number" />
            </custom-floating-label>
        </div>
        <div class="buffer-input">
            <custom-floating-label
                :config="{
                    label: 'Buffer Distance 2',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <input v-model.number="bufferDistance2" type="number" />
            </custom-floating-label>
        </div>
        <div class="buffer-input">
            <custom-floating-label
                :config="{
                    label: 'Buffer Distance Unit',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <select v-model="bufferUnit" class="drawInput">
                    <option
                        v-for="(unit, index) in bufferUnits"
                        :key="index"
                        :value="unit.toLowerCase()"
                    >
                        {{ unit }}
                    </option>
                </select>
            </custom-floating-label>
        </div>
        <div
            class="buffer-buttons d-align-items-end"
            style="padding-bottom: 15px;"
        >
            <button
                class="btn btn-buffer"
                :class="
                    activeMapTool == 'buffer'
                        ? 'btn-selected'
                        : 'btn-unselected'
                "
                v-on:click="createBufferPoint"
            >
                Create Buffer
            </button>
            <button class="btn btn-clear btn-buffer" v-on:click="clearBuffers">
                Clear
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import CustomFloatingLabel from '../../CustomFloatingLabel.vue';
export default {
    name: 'BufferDraw',
    components: {
        CustomFloatingLabel,
    },
    // not using the mixin since there is less overlap than
    //  between the ICS and Whiteboard draw
    mixins: [],
    props: {},
    data() {
        return {
            bufferDistance1: null,
            bufferDistance2: null,
            bufferUnit: 'meters',
            bufferUnits: ['Meters', 'Kilometers', 'Feet', 'Miles'],
        };
    },
    methods: {
        ...mapActions('oneMap', [
            'setMapTool',
            'clearMapTool',
            'clearDrawBuffers',
        ]),
        ...mapMutations('oneMap', ['addDrawBuffers']),
        createBufferPoint() {
            if (
                ((typeof this.bufferDistance1 === 'number' &&
                    this.bufferDistance1 > 0) ||
                    this.bufferDistance1 === null) &&
                ((typeof this.bufferDistance2 === 'number' &&
                    this.bufferDistance2 > 0) ||
                    this.bufferDistance2 === null)
            ) {
                this.setMapTool('buffer');
            } else {
                alert('Please enter a valid buffer distance');
            }
        },
        clearBuffers() {
            if (this.activeMapTool === 'buffer') {
                this.clearMapTool();
            }
            this.clearDrawBuffers();
        },
        convertDistanceToMeters(distance, unit) {
            const conversions = {
                meters: 1,
                kilometers: 1000,
                feet: 0.3048,
                miles: 1609.34,
            };

            let convertedDistance = distance * conversions[unit.toLowerCase()];
            return convertedDistance.toFixed(6);
        },
    },
    computed: {
        ...mapState('oneMap', [
            'activeMapTool',
            'clickCoords',
            'bufferMarkers',
        ]),
    },
    watch: {
        clickCoords(newCoords, oldCoords) {
            if (this.activeMapTool === 'buffer') {
                this.clearBuffers();
                var buffers = [
                    {
                        id: 'B0',
                        geometry: {
                            coordinates: [newCoords.lat, newCoords.lng],
                        },
                        styling: {
                            PolygonBorderColour: 'black',
                            PolygonColour: 'black',
                            // PolygonStyle: '1000',
                            PolygonTransparency: 0,
                            SymbologySize: 0.1,
                        },
                    },
                ];
                if (this.bufferDistance1 > 0) {
                    buffers.push({
                        id: 'B1',
                        geometry: {
                            coordinates: [newCoords.lat, newCoords.lng],
                        },
                        styling: {
                            PolygonBorderColour: 'black',
                            PolygonColour: 'dark grey',
                            PolygonStyle: '10,10',
                            PolygonTransparency: 0,
                            SymbologySize: this.convertDistanceToMeters(
                                parseFloat(this.bufferDistance1),
                                this.bufferUnit
                            ),
                        },
                    });
                }
                if (this.bufferDistance2 > 0) {
                    buffers.push({
                        id: 'B2',
                        geometry: {
                            coordinates: [newCoords.lat, newCoords.lng],
                        },
                        styling: {
                            PolygonBorderColour: 'black',
                            PolygonColour: 'dark grey',
                            PolygonStyle: '10,10',
                            PolygonTransparency: 0,
                            SymbologySize: this.convertDistanceToMeters(
                                parseFloat(this.bufferDistance2),
                                this.bufferUnit
                            ),
                        },
                    });
                }
                this.addDrawBuffers(buffers);
                this.clearMapTool();
            }
        },
    },
};
</script>

<style scoped>
.buffer-container {
    container-type: inline-size;
    container-name: buffer-sidebar;
}

.buffer-input {
    padding: 0 15px 0 15px;
    width: 20cqi;
}

.buffer-buttons {
    display: inline-flex;
    justify-content: flex-end;
    width: 335px;
}

.descText {
    overflow-wrap: normal;
    width: 322px;
}

.EM {
    padding-left: 20px;
    max-width: 302px;
}

@buffer-container buffer-sidebar {
    .buffer-input .input-container {
        width: 85cqi;
    }
}
</style>
<style></style>

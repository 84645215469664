var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!Object.keys(_vm.drawTypes).length)?_c('div',{staticClass:"descText no-draw-types"},[_vm._v(" No layers available ")]):_c('div',[_c('div',{staticClass:"descText"},[_vm._v(" Please use the following drop-downs to select a layer to add a geometry feature and populate its attributes ")]),_c('div',{staticClass:"EM"},[_c('custom-floating-label',{attrs:{"config":{
                    label: 'Editing type',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDrawCategory),expression:"selectedDrawCategory"}],staticClass:"drawInput",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedDrawCategory=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.drawCategories),function(group,index){return _c('option',{key:index,domProps:{"value":group}},[_vm._v(" "+_vm._s(group)+" ")])}),0)])],1),_c('div',{staticClass:"EM"},[_c('custom-floating-label',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSecondSelect),expression:"showSecondSelect"}],attrs:{"config":{
                    label: _vm.secondLabel,
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedLocGroup),expression:"selectedLocGroup"}],staticClass:"drawInput",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedLocGroup=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.locGroups),function(shape,index){return _c('option',{key:index,domProps:{"value":shape}},[_vm._v(" "+_vm._s(shape)+" ")])}),0)])],1),_c('div',{staticClass:"EM"},[_c('custom-floating-label',{directives:[{name:"show",rawName:"v-show",value:(_vm.showThirdSelect),expression:"showThirdSelect"}],attrs:{"config":{
                    label: _vm.thirdLabel,
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }}},[_c('b-dropdown',{staticClass:"ICS-SubTypes ICS-SubTypes-dropdown"},_vm._l((_vm.locTypes),function(type,index){return _c('b-dropdown-item',{key:index,staticClass:"px-1",attrs:{"value":type[0]},on:{"click":function($event){return _vm.setSelectedLocType(type)}}},[_c('div',{staticClass:"d-flex align-items-center"},[(type[0] !== '---')?_c('img',{staticClass:"pr-1",attrs:{"src":_vm.iconSource(type[1]),"width":"30","height":"30","onerror":"handleImageError"}}):_vm._e(),_vm._v(" "+_vm._s(type[0])+" ")])])}),1),_c('div',{staticClass:"d-flex flex-row  ICS-SubTypes ICS-SubTypes__overlay"},[(_vm.selectedLocType[0] !== '---')?_c('input',{staticClass:"ICS-SubTypes__input",attrs:{"disabled":"","type":"image","id":"ICS-SubTypes__icon","src":_vm.iconSource(_vm.selectedLocType[1]),"onerror":"handleImageError"}}):_vm._e(),_c('input',{staticClass:"ICS-SubTypes__input",attrs:{"disabled":"","id":"ICS-SubTypes__label"},domProps:{"value":_vm.selectedLocType[0]}}),_c('i',{staticClass:"arrow down"})])],1)],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDrawButton),expression:"showDrawButton"}],staticClass:"btn btn-gray btn-draw",on:{"click":_vm.startDraw}},[_vm._v(" Start drawing ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
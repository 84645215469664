<template>
    <div>
        <!-- if we don't have any draw types, inform the user, and basically show an empty frame -->
        <div
            class="descText no-draw-types"
            v-if="!Object.keys(drawTypes).length"
        >
            No layers available
        </div>
        <div v-else>
            <div class="descText">
                Please use the following drop-downs to select a layer to add a
                geometry feature and populate its attributes
            </div>
            <div class="EM">
                <custom-floating-label
                    :config="{
                        label: 'Editing type',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <select v-model="selectedDrawCategory" class="drawInput">
                        <option
                            v-for="(group, index) in drawCategories"
                            :key="index"
                            :value="group"
                        >
                            {{ group }}
                        </option>
                    </select>
                </custom-floating-label>
            </div>
            <!-- only show if the previous select has a value selected, is not '---' -->
            <div class="EM">
                <custom-floating-label
                    v-show="showSecondSelect"
                    :config="{
                        label: secondLabel,
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <select v-model="selectedLocGroup" class="drawInput">
                        <option
                            v-for="(shape, index) in locGroups"
                            :key="index"
                            :value="shape"
                        >
                            {{ shape }}
                        </option>
                    </select>
                </custom-floating-label>
            </div>
            <!-- only show if the previous select has a value selected, is not '---' -->
            <div class="EM">
                <custom-floating-label
                    v-show="showThirdSelect"
                    :config="{
                        label: thirdLabel,
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <!-- custom dropdown that sill attempt to show the image for the feature type if one is available -->
                    <b-dropdown class="ICS-SubTypes ICS-SubTypes-dropdown">
                        <b-dropdown-item
                            v-for="(type, index) in locTypes"
                            :key="index"
                            :value="type[0]"
                            @click="setSelectedLocType(type)"
                            class="px-1"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    v-if="type[0] !== '---'"
                                    :src="iconSource(type[1])"
                                    width="30"
                                    height="30"
                                    class="pr-1"
                                    onerror="handleImageError"
                                />
                                {{ type[0] }}
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                    <div
                        class="d-flex flex-row  ICS-SubTypes ICS-SubTypes__overlay"
                    >
                        <input
                            v-if="selectedLocType[0] !== '---'"
                            disabled
                            type="image"
                            class="ICS-SubTypes__input"
                            id="ICS-SubTypes__icon"
                            :src="iconSource(selectedLocType[1])"
                            onerror="handleImageError"
                        />
                        <input
                            disabled
                            class="ICS-SubTypes__input"
                            id="ICS-SubTypes__label"
                            :value="selectedLocType[0]"
                        />
                        <i class="arrow down"></i>
                    </div>
                </custom-floating-label>
            </div>
            <div class="d-flex justify-content-center">
                <button
                    class="btn btn-gray btn-draw"
                    v-show="showDrawButton"
                    @click="startDraw"
                >
                    Start drawing
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import CustomFloatingLabel from '../../CustomFloatingLabel.vue';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { spatialFunctions } from '../../../utilities/spatialFunctions';
import { spatialService } from '../../../services/spatial.services';
import { drawingMixin } from '../../../mixins/drawing.mixin';
export default {
    name: 'IcsDraw',
    components: {
        CustomFloatingLabel,
    },
    mixins: [drawingMixin],
    props: {
        drawTypes: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedDrawCategory: '---',
            selectedLocGroup: '---',
            selectedLocType: ['---', '---'],
            firstLabel: 'Editing Type',
            blobPath:
                process.env.VUE_APP_BLOB_PATH + 'resources/icons/ERIcons/',
            wktGeom: null,
        };
    },
    methods: {
        ...mapActions('emergencyManagement', [
            'setDrawingAttributes',
            'activateERLayers',
            'updateDrawingGeometry',
        ]),
        ...mapMutations('emergencyManagement', {
            setShowAttributeModal: 'updateShowAttributeModal',
        }),
        setSelectedLocType(type) {
            this.selectedLocType = type;
        },
        // get the icon source for the different LocTypes
        iconSource(blobName) {
            return this.blobPath + blobName + '.svg';
        },
        handleImageError(event) {
            event.target.src = `${this.blobPath}Not-Available.svg`;
        },
        // figure out which feature type to start drawing
        startDraw() {
            this.activateERLayers();
            if (
                this.selectedLocGroup.toLowerCase().includes('point') ||
                this.selectedLocGroup == 'ICS'
            ) {
                this.drawPoint();
            } else if (this.selectedLocGroup.toLowerCase().includes('line')) {
                this.drawLine();
            } else if (
                this.selectedLocGroup.toLowerCase().includes('polygon')
            ) {
                this.drawPolygon();
            }
        },
        pointClickHandler(markerFeature) {
            this.clearMapTool();
            this.$store.commit('oneMap/addDrawMarker', markerFeature);
            spatialService
                .validateGeometry({
                    Geometry: spatialFunctions.convertToWKT(
                        this.drawMarkers.at(-1)
                    ),
                })
                .then((isValid) => {
                    if (isValid) {
                        this.setShowAttributeModal(true);
                        this.showAttributeModal(
                            spatialFunctions.convertToWKT(
                                this.drawMarkers.at(-1)
                            )
                        );
                    }
                });
        },
        lineDoubleClickHandler(isSaving) {
            let drawLines = this.drawLines;
            // remove the last coordinate, the 2nd click in a double-click
            drawLines.at(-1).geometry.coordinates.pop();
            this.$store.commit('oneMap/mutateDrawLines', drawLines);
            if (isSaving) {
                // if saving, open the attribute modal to fill in properties
                this.showAttributeModal(
                    spatialFunctions.convertToWKT(drawLines.at(-1))
                );
            }
        },
        polygonDoubleClickHandler(isSaving) {
            let drawLines = this.drawLines;
            // remove the border line when using a polygon since we use the polygon border now
            drawLines.pop();
            this.$store.commit('oneMap/mutateDrawLines', drawLines);
            var drawPolygons = this.drawPolygons;
            // update the styling now that it is a more permanent feature
            drawPolygons.at(-1).styling.PolygonTransparency = 0;
            // remove the last coordinate, the 2nd click in a double-click
            drawPolygons.at(-1).geometry.coordinates[0].pop();
            this.$store.commit('oneMap/mutateDrawPolygons', drawPolygons);
            if (isSaving) {
                // if saving, open the attribute modal to fill in properties
                this.showAttributeModal(
                    spatialFunctions.convertToWKT(drawPolygons.at(-1))
                );
            }
        },
        showAttributeModal(wkt) {
            this.$emit('openAttributeModal');
            let feature = {
                properties: [
                    {
                        label: 'geoID',
                        value: 0,
                    },
                    {
                        label: 'filler',
                        value: 0,
                    },
                    {
                        label: 'geomLocName',
                        value: null,
                    },
                    {
                        label: 'geomLocType',
                        value: this.drawTypes[this.selectedDrawCategory][
                            this.selectedLocGroup
                        ][this.selectedLocType[0]],
                    },
                    {
                        label: 'geomLocDesc',
                        value: null,
                    },
                    {
                        label: 'geomComment',
                        value: null,
                    },
                    {
                        label: 'geomLocIcon',
                        value:
                            this.blobPath +
                            this.drawTypes[this.selectedDrawCategory][
                                this.selectedLocGroup
                            ][this.selectedLocType[0]] +
                            '.svg',
                    },
                ],
                geometry: wkt,
                projectID: this.ProjectID,
            };
            this.setDrawingAttributes(feature);
            this.setShowAttributeModal(true);
        },
    },
    computed: {
        ...mapGetters('projects', ['ProjectID']),

        // most of this component is dynamic as the previous dropdowns are selected/deselected, including the final 'Start drawing' button
        secondLabel() {
            return (
                {
                    '---': '---',
                    'Emergency management': 'EM',
                    'Emergency response': 'ER',
                    'Contamination and remediation': 'CAR',
                }[this.selectedDrawCategory] + ' sub type'
            );
        },
        thirdLabel() {
            return this.selectedLocGroup + ' feature';
        },
        // top level select options
        drawCategories: function() {
            return Object.keys(this.drawTypes);
        },
        showSecondSelect: function() {
            return this.selectedDrawCategory != '---';
        },
        // second level select options
        locGroups: function() {
            if (this.selectedDrawCategory == '---') {
                return [];
            } else {
                return Object.keys(this.drawTypes[this.selectedDrawCategory]);
            }
        },
        showThirdSelect: function() {
            return (
                this.selectedDrawCategory != '---' &&
                this.selectedLocGroup != '---'
            );
        },
        // third level select options
        locTypes: function() {
            if (
                this.selectedDrawCategory == '---' ||
                this.selectedLocGroup == '---'
            ) {
                return [['---', '---']];
            }
            return Object.entries(
                this.drawTypes[this.selectedDrawCategory][this.selectedLocGroup]
            );
        },
        showDrawButton: function() {
            return (
                this.selectedLocType[0] != '---' &&
                this.selectedLocType[0] != null
            );
        },
    },
    watch: {
        // update the 2nd level if the 1st level changes,
        //  this will cascade to clear the 3rd level with the other watcher
        selectedDrawCategory: function(newVal, oldVal) {
            if (newVal != oldVal) {
                this.selectedLocGroup = this.locGroups[0];
            }
        },
        // update the 3rd level if the 2nd level changes
        selectedLocGroup: function(newVal, oldVal) {
            if (newVal != oldVal) {
                this.selectedLocType = this.locTypes[0];
            }
        },
    },
};
</script>

<style>
.btn-gray {
    background-color: gray !important;
    color: white;
    height: 42px;
    width: 135px !important;
}
.btn-gray:focus {
    background-color: rgb(77, 194, 233) !important;
}
.btn-gray:active {
    background-color: rgb(77, 194, 233) !important;
}
.btn-gray:hover {
    background-color: darkgrey;
}

.btn-draw {
    margin-top: 7px;
}
.label {
    margin-left: 5px;
}
.descText {
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 10px;
    padding-bottom: 10px;
}
.drawInput {
    border: none !important;
    padding-top: 15px !important;
}
.EM {
    padding-left: 15px;
}
ul.dropdown-menu.show {
    overflow: auto;
    min-height: fit-content;
    max-height: 50vh;
    border-radius: 0;
    padding: 0;
    margin: 0;
    border-color: rgb(118, 118, 118);
    width: 302px;
    box-shadow: 0 2px 5px #bdbdbd !important;
}
ul.dropdown-menu li {
    padding: 0px 2px 1px !important;
}
a.dropdown-item {
    padding: 0;
    background-color: none !important;
    cursor: default !important;
}
a.dropdown-item:hover {
    background-color: transparent !important;
}
ul.dropdown-menu li:hover {
    background-color: #007bff;
}
ul.dropdown-menu li a:hover {
    color: white !important;
}

input#ICS-SubTypes__input {
    padding: 0 0;
    color: black;
    font-weight: 600;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: inherit;
    font-size: 14px;
    margin-left: 5px;
}
button.dropdown-toggle.btn-secondary {
    background-color: transparent !important;
    border: none !important;
    height: 48px !important;
    cursor: default !important;
}
.ICS-SubTypes {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-family: var(--ffr);
}
.ICS-SubTypes__overlay {
    z-index: 7;
    display: flex;
    align-items: flex-start;
    width: 80%;
    padding-top: 5px;
    flex-direction: column;
}
.ICS-SubTypes-dropdown {
    z-index: 100;
}

.ICS-SubTypes ul.dropdown-menu.show {
    overflow: auto;
    min-height: fit-content;
    max-height: 50vh;
    border-radius: 0;
    padding: 0;
    margin: 0;
    border-color: rgb(118, 118, 118);
    width: 302px;
    box-shadow: 0 2px 5px #bdbdbd !important;
}

.ICS-SubTypes ul.dropdown-menu li div {
    padding: 0px 2px 1px !important;
}
.ICS-SubTypes a.dropdown-item {
    padding: 0;
    background-color: none !important;
    cursor: default !important;
}

.ICS-SubTypes a.dropdown-item:hover {
    background-color: transparent !important;
}
.ICS-SubTypes ul.dropdown-menu li:hover {
    background-color: #007bff;
}
.ICS-SubTypes ul.dropdown-menu li a:hover {
    color: white !important;
}

input.ICS-SubTypes__input {
    padding: 0 0;
    color: black;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: inherit;
    font-size: 16px;
    margin-left: 5px;
    font-weight: 600;
    letter-spacing: 0.8px;
}
.ICS-SubTypes button.dropdown-toggle.btn-secondary {
    background-color: transparent !important;
    border: none !important;
    height: 48px !important;
    cursor: default !important;
}
.ICS-SubTypes {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.ICS-SubTypes .arrow {
    border: solid rgb(29, 29, 29);
    border-width: 0 0.18rem 0.18rem 0;
    display: inline-block;
    padding: 0.18rem;
    margin: auto;
    height: auto;
    margin-right: 0.35rem;
    margin-top: 13px;
}
.ICS-SubTypes .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

#ICS-SubTypes__icon {
    flex-grow: 1;
    height: 28px;
    width: auto;
    padding-left: 0 !important;
    align-self: center;
}
#ICS-SubTypes__label {
    flex-grow: 8;
    color: #1d1d1d;
}
.EM {
    display: flex;
    flex-flow: column wrap;
}
.EM .dropdown-toggle.btn-secondary {
    padding-bottom: 0.02rem !important;
    padding-left: 0 !important;
}
.ICS-SubTypes-wrapper {
    display: flex;
    flex-direction: column;
}
</style>
<style scoped>
@media screen and (min-width: 768px) {
    .input__container .slot-container input {
        width: 300px;
    }
}
select {
    font-size: 16px !important;
}
</style>
